@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Playpen+Sans&display=swap');

.checkbox > div > span:nth-child(2){
   display: none;
}

.stepper-none::-webkit-outer-spin-button,
.stepper-none::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.stepper-none[type=number] {
  -moz-appearance: textfield;
}